<template>
  <el-main>
    <el-form class="set-order" ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item class="item" label="分类名称：" prop="classify_name">
        <el-input v-model="form.classify_name" maxlength="10"></el-input>
        <span class="sp2">分类名称建议12符号/6字以内</span>
      </el-form-item>
      <el-form-item class="item" label="组内权重：">
        <el-input type="number" :min="0" placeholder="请输入内容" v-model="form.rank"></el-input>
        <span class="sp2">数字越大，排名越靠前；如为空，排序顺序为添加时间</span>
      </el-form-item>
      <!-- <el-form-item class="item type-img" label="分类图片 ：" prop="picture">
        <div>
          <AddImg @getImg="getTypeImg" :path="form.picture" :height="110" :width="110"></AddImg>
          <p style="margin-top: 10px">一级，二级分类建议图片尺寸526*200px</p>
        </div>
      </el-form-item> -->
      <el-form-item class="item" label="是否显示：">
        <el-switch v-model="form.is_show"></el-switch>
      </el-form-item>
      <!-- <div class="item" style="padding-left: 130px; margin: 30px 0">
        <el-button size="medium" @click="onSubmit" type="primary">保存</el-button>
      </div> -->
      <Preservation @preservation="onSubmit('form')">
        <router-link to="/goods/GoodsType" slot="return" v-if="form.id" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
  </el-main>
</template>

<script>
// import AddImg from '@/components/addImg';
import Preservation from '@/components/preservation';
export default {
  components: {
    // AddImg,
    Preservation,
  },
  data() {
    return {
      form: {
        id: 0,
        is_show: !0,
        classify_name: '',
        rank: '',
      },
      disabled_type:!1,
      rules: {
        classify_name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        // picture: [{ required: true, message: '请添加分类图片', trigger: 'blur' }],
      },
    };
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    if (this.$route.params.id) {
      let data = JSON.parse(this.$route.params.id);
      this.form.is_show = data.is_show;
      this.form.classify_name = data.classify_name;
      this.form.rank = data.rank;
      this.form.id = data.id;
    }
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let data = {
            is_show: this.form.is_show ? 1 : 0,
            rank: this.form.rank || 99,
            classify_name: this.form.classify_name,
          };
          if (this.form.id) data.id = this.form.id;
          let url = data.id ? this.$api.goods.editType : this.$api.goods.addType;
          this.$axios.post(url, data).then((res) => {
            if (res.code == 0) {
              this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`);
              this.$router.push('/goods/goodsClassify');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getTypeImg(val) {
      this.form.picture = val;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  padding: 50px 0 0 100px;
  background: #fff;
  .set-order {
    width: 1000px;
    .item {
      .el-input,
      .el-select {
        width: 500px;
      }
      .sp2 {
        margin-left: 20px;
        color: #999;
      }
    }
    .type-img {
      align-items: flex-start;
      span {
        margin-top: 10px;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
